// extracted by mini-css-extract-plugin
export var blink = "Content-module--blink--ade6f";
export var body = "Content-module--body--726c2";
export var content = "Content-module--content--80d58";
export var fadeaway = "Content-module--fadeaway--ebd55";
export var fadein = "Content-module--fadein--67061";
export var justfade = "Content-module--justfade--01253";
export var justpushup = "Content-module--justpushup--fe929";
export var title = "Content-module--title--09504";
export var typing = "Content-module--typing--6cdc6";
export var widthgrow = "Content-module--widthgrow--85c8a";