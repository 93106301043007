// extracted by mini-css-extract-plugin
export var blink = "Post-module--blink--56fcc";
export var btnLine = "Post-module--btn-line--09116";
export var buttonArticles = "Post-module--buttonArticles--d793a";
export var comments = "Post-module--comments--d3b99";
export var content = "Post-module--content--3c6e5";
export var emptyWrap = "Post-module--emptyWrap--5707a";
export var fadeaway = "Post-module--fadeaway--c4aa7";
export var fadein = "Post-module--fadein--ea63d";
export var footer = "Post-module--footer--f8705";
export var justfade = "Post-module--justfade--e0547";
export var justpushup = "Post-module--justpushup--78718";
export var linkWrap = "Post-module--linkWrap--04ff1";
export var post = "Post-module--post--3a994";
export var typing = "Post-module--typing--0cff9";
export var widthgrow = "Post-module--widthgrow--45415";
export var wrapper = "Post-module--wrapper--f2e61";