import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { Button } from "@/components/Button";
import { ThemeSwitcher } from "@/components/ThemeSwitcher";
import { Author } from "./Author";
import { Comments } from "./Comments";
import { Content } from "./Content";
import { Meta } from "./Meta";
import { Tags } from "./Tags";
import { Page } from "@/components/Page";
import { Sidebar } from "@/components/Sidebar";
import { Link } from "gatsby";

import * as styles from "./Post.module.scss";

interface Props {
  post: Node;
}

const Post: React.FC<Props> = ({ post }: Props) => {
  const { html } = post;
  const { tagSlugs, slug } = post.fields;
  
  const { tags, emoji, title, date, image } = post.frontmatter;

  // Safely get the image data using getImage only if image exists
  const imageData = image ? getImage(image) : null;

  return (
    <Page>
      <div className="right-side-img">
        {imageData ? (
          <GatsbyImage image={imageData} alt={title} style={{ width: '100%', height: '100%' }} />
        ) : (
          image?.publicURL && (
            <div style={{ backgroundImage: `url(${image.publicURL})`, width: '100%', height: '100%' }}>
              {/* Additional content if needed */}
            </div>
          )
        )}
      </div>
      
      <div className={styles.post}>
        <div className={styles.content}>
          <div className="top-meta">
            <div className="titleA">{title}</div>
            <h4>{date}</h4>
            <h5>{emoji} {tags}</h5>
          </div>

          <Button className={styles.buttonArticles} title="close" to="/" />

          <Content body={html} />
          <Author />

          <div className="top-meta">
            <div className="titleA">{title}</div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Post;
